<template>
  <div>
    <el-dialog width="70%" :title="!dataForm.id ? '新增' : '查看'" :close-on-click-modal="false" :visible.sync="visible">
      <h3 style="padding: 2vh 0; border-top: 1px solid rgba(184, 184, 184, 0.6)">Push内容信息配置</h3>
      <el-form :model="dataForm" ref="dataForm" label-width="125px" :rules="dataRule">
        <div>
          <h4 style="margin: 0 0 2vh 2vw;">发布角色：</h4>
          <div style="display: flex;">

            <el-form-item label="覆盖IP：" prop="ipList">
              <el-select v-model="dataForm.ipList" clearable multiple placeholder="--请选择--" size="small"
                @change="selectIpListChange" :disabled="dataForm.id !== 0">
                <el-option v-for="item in virIPOpt" :key="item.ip" :label="item.ipName" :value="item.ip">
                </el-option>
              </el-select>
            </el-form-item>

            <el-form-item label="覆盖角色：" prop="virUserList">
              <el-select v-model="dataForm.virUserList" clearable multiple placeholder="--请选择--" size="small"
                :disabled="dataForm.id !== 0" @change="selectUserChange">
                <!-- <el-option v-for="item in virUserOpt" :key="item.id" :label="item.name" :value="item.id">
                </el-option> -->
                <el-option-group v-for="group in virUserOpt" :key="group.ip" :label="group.ipName">
                  <el-option v-for="item in group.virUserResDtoList" :key="item.id" :label="item.name" :value="item.id">
                  </el-option>
                </el-option-group>
              </el-select>
            </el-form-item>
          </div>
        </div>

        <div v-if="dataForm.id === 0">
          <el-form-item label="Push语种：" prop="language">
            <el-select v-model="dataForm.language" clearable placeholder="all" size="small" @change="changeContentList">
              <el-option v-for="item in languageOpt" :key="item.value" :label="item.label" :value="item.value">
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="Push内容：" prop="contentList">
            <template slot-scope="{}" slot="label">
              <span>Push内容：</span>
              <el-tooltip class="item" effect="light" placement="top">
                <i class="el-icon-question" style="font-size: 14px; vertical-align: middle;"></i>
                <div slot="content">
                  联动Push语种，请先选择Push语种
                </div>
              </el-tooltip>
            </template>
            <div v-for="(item, index) in dataForm.contentList" :key="index">
              <el-input type="textarea" placeholder="请输入内容" v-model="item.content" class="input-width" resize="none"
                :rows="2" :disabled="dataForm.language === ''">
              </el-input>
              <el-button type="primary" icon="el-icon-circle-plus-outline" circle size="small"
                style="margin: 0 0 4vh 1vw;" @click="addContent"></el-button>
              <el-button v-show="contentNum !== 1" type="danger" icon="el-icon-delete" circle size="small"
                @click="deleteContent(item, index)"></el-button>
            </div>
          </el-form-item>
        </div>
        <div v-else>
          <h4 style="margin: 0 0 2vh 2vw;">Push内容：</h4>
          <el-table :data="contentTableData" :span-method="objectSpanMethod" border
            style="width: 90%; margin: 2vh 2vw 5vh;" max-height="250">
            <el-table-column prop="lang" label="语种" header-align="center" align="center" width="150">
            </el-table-column>
            <el-table-column prop="content" label="Push内容" header-align="center" align="center">
            </el-table-column>
          </el-table>
        </div>


        <h3>Push推送信息配置</h3>
        <div style="display: flex;">
          <el-form-item label="发布国家：" prop="sendCcList">
            <el-select v-model="dataForm.sendCcList" clearable multiple placeholder="all" size="small"
              :disabled="dataForm.id !== 0">
              <el-option v-for="item in sendCcListOpt" :key="item.value" :label="item.label" :value="item.value">
              </el-option>
            </el-select>
          </el-form-item>
        </div>

        <el-form-item label="发布时间：" prop="timeList">
          <div v-for="(item, index) in dataForm.timeList" :key="index">
            <el-time-picker v-model="item.time" placeholder="任意时间点" value-format="HH:mm:ss" :readonly="dataForm.id !== 0"
              style="margin-bottom: 2vh;"></el-time-picker>
            <el-button type="primary" icon="el-icon-circle-plus-outline" circle size="small" style="margin: 0 0 4vh 1vw;"
              @click="addTime" v-show="dataForm.id === 0"></el-button>
            <el-button v-show="timeNum !== 1 && dataForm.id === 0" type="danger" icon="el-icon-delete" circle size="small"
              @click="deleteTime(item, index)"></el-button>
          </div>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <template v-if="dataForm.id === 0">
          <el-button @click="visible = false">取消</el-button>
          <el-button type="primary" @click="beforeSubmit()">确定</el-button>
        </template>

      </span>
    </el-dialog>
  </div>
</template>
<script>
const sourceDataForm = {
  id: 0,
  contentListBylang: [{
    lang: 'ar',
    contentList: [
      {
        content: '',
      },
    ]
  },
  {
    lang: 'en',
    contentList: [
      {
        content: '',
      },
    ]
  }],
  contentList: [
    {
      content: '',
    },
  ],
  pushContentList: {
    ar: [],
    en: [],
  },
  ipList: [],
  virUserList: [],
  sendCcList: '',
  language: '',
  langList: [],
  sendTimeList: [],
  timeList: [
    {
      time: ''
    }
  ]
}
export default {
  data() {
    return {
      contentNum: 1,
      timeNum: 1,
      visible: false,
      dataForm: JSON.parse(JSON.stringify(sourceDataForm)),
      sendCcListOpt: [
        {
          value: 'all',
          label: 'ALL'
        }
      ],
      languageOpt: [
        {
          value: 'ar',
          label: '阿拉伯语'
        },
        {
          value: 'en',
          label: '英语'
        }
      ],
      virIPOpt: [],
      virUserOpt: [],
      dataRule: {
        'ipList': [
          { required: true, message: '覆盖IP不能为空', trigger: 'blur' }
        ],
        'virUserList': [
          { required: true, message: '覆盖角色不能为空', trigger: 'blur' }
        ],
        'language': [
          { required: true, message: 'Push语种不能为空', trigger: 'blur' }
        ],
        'sendCcList': [
          { required: true, message: '发布国家不能为空', trigger: 'blur' }
        ],
        'sendTimeList': [
          { required: true, message: '发送时间不能为空', trigger: 'blur' }
        ],
        'contentList': [
          { required: true, message: '发送内容不能为空', trigger: 'blur' }
        ],
      },
      contentTableData: [],
      previousIpOptValue: [],
    }
  },
  mounted() {
    this.getIpList()
  },
  methods: {
    getIpList() {
      this.$http({
        url: this.$http.adornUrl(`/admin/pushTaskNew/virIplist?ip=0`),
        method: 'post',
      }).then(({ data }) => {
        if (data && data.code === 0) {
          this.virIPOpt = JSON.parse(JSON.stringify(data.result))
          // this.virIPOpt.map(e => { delete e.virUserResDtoList }) //然后删除属性virUserResDtoList
          this.virIPOpt = this.virIPOpt.filter(item => !["", null, undefined, NaN].includes(item.ip))
        } else {
          this.$message.error(data.msg)
        }
      })
    },

    selectIpListChange(val) {
      let temp = Array.isArray(val) ? `${val.length === 0 ? 0 : val[val.length - 1]}` : val
      if (temp == 0) {
        this.dataForm.virUserList = []
        this.virUserOpt = []
      } else {
        this.virUserOpt.length = 0
        val.forEach(item => {
          // this.virUserOpt.length = 0
          if (item) {
            this.selectIpChange(item)
          }
        })
      }
    },

    selectIpChange(val) {
      if (val) {
        this.$http({
          url: this.$http.adornUrl(`/admin/pushTaskNew/virIplist?ip=${val}`),
          method: 'post',
        }).then(({ data }) => {
          if (data && data.code === 0) {
            if (data.result.length !== 0) {
              this.virUserOpt = this.virUserOpt.concat(data.result[0])
              let map = new Map();
              for (let item of this.virUserOpt) {
                if (!map.has(item.ip)) {
                  map.set(item.ip, item)
                }
                item.virUserResDtoList = Array.from(new Set(item.virUserResDtoList.map(JSON.stringify)), JSON.parse)
              }
              this.virUserOpt = [...map.values()]

              this.virUserOpt = this.virUserOpt.filter(function (item, index) {
                return item['ip'] != 'all'
              })
              this.virUserOpt.push({
                ip: 'all',
                name: 'ALL',
                virUserResDtoList: [{
                  id: 'all',
                  name: 'ALL'
                }]
              })
              this.previousIpOptValue = JSON.parse(JSON.stringify(this.virUserOpt))
            }
          }
        })


      }
    },

    selectUserChange(val) {
      if (val.includes('all')) {
        this.dataForm.virUserList = val.filter(item => item == 'all')
      }
    },

    flitterData(arr) {
      let spanOneArr = []
      let concatOne = 0
      arr.forEach((item, index) => {
        if (index === 0) {
          spanOneArr.push(1)
        } else {
          if (item.lang === arr[index - 1].lang) { //第一列需合并相同内容的判断条件
            spanOneArr[concatOne] += 1
            spanOneArr.push(0)
          } else {
            spanOneArr.push(1)
            concatOne = index
          }
        }
      })
      return {
        one: spanOneArr,
      }
    },

    objectSpanMethod({ rowIndex, columnIndex }) {
      if (columnIndex === 0) {
        const _row = (this.flitterData(this.contentTableData).one)[rowIndex];
        const _col = _row > 0 ? 1 : 0;
        return {
          rowspan: _row,
          colspan: _col
        }
      }
    },

    init(row) {
      // console.log('row', row)
      this.virUserOpt = []
      this.dataForm = JSON.parse(JSON.stringify(sourceDataForm))
      this.contentTableData = []
      this.dataForm.id = !row ? 0 : row.id
      if (this.dataForm.id === 0) {
        // 新增模式
        this.visible = true
        this.contentNum = 1
        this.timeNum = 1
        this.virUserOpt = []
        return;
      }

      if (this.$refs['dataForm']) this.$refs['dataForm'].resetFields()

      this.visible = true

      const { ipList, sendCcList, sendTimeList, virUserList, pushContentList } = row
      this.dataForm.sendCcList = sendCcList.replace(/\[|]/g, '').replace(/["]/g, "'").split(',')
      this.dataForm.ipList = ipList.replace(/\[|]/g, '').replace(/["']/g, "").split(',')
      this.dataForm.virUserList = virUserList.replace(/\[|]/g, '').replace(/["']/g, "").split(',')
      this.dataForm.sendTimeList = sendTimeList.replace(/\[|]/g, '').replace(/["]/g, "'").split(',')
      this.dataForm.pushContentList = eval("(" + pushContentList + ")")

      this.dataForm.pushContentList.ar.forEach(item => {
        this.contentTableData.push({
          lang: 'ar',
          content: item,
        })
      })

      this.dataForm.pushContentList.en.forEach(item => {
        this.contentTableData.push({
          lang: 'en',
          content: item,
        })
      })

      if (this.dataForm.id !== 0) {
        this.dataForm.ipList.forEach(item => {
          this.selectIpChange(item)
        })
      }

      // let sendTimeObj = {}
      // for (let key in this.dataForm.sendTimeList) {
      //   sendTimeObj[key] = this.dataForm.sendTimeList[key];
      // }
      // this.dataForm.timeList = Object.keys(sendTimeObj).map(val => ({
      //   time: sendTimeObj[val],
      // }))
      this.dataForm.timeList.splice(0, 1)
      this.dataForm.sendTimeList.forEach(item => {
        this.dataForm.timeList.push({
          time: item
        })
      })

      this.timeNum = this.dataForm.timeList.length
    },

    changeContentList(val) {
      this.dataForm.contentListBylang.forEach(item => {
        if (val === item.lang) {
          this.dataForm.contentList = item.contentList
          this.contentNum = this.dataForm.contentList.length
        }
      });
    },

    addContent() {
      this.contentNum++
      this.dataForm.contentList.push(
        {
          content: '',
        }
      )
      this.dataForm.contentListBylang.forEach(item => {
        if (this.dataForm.language === item.lang) {
          item.contentList = this.dataForm.contentList
        }
      });

    },

    deleteContent(item, index) {
      this.contentNum--
      this.dataForm.contentList.splice(index, 1)
    },

    addTime() {
      this.timeNum++
      this.dataForm.timeList.push({
        time: '',
      })
    },

    deleteTime(item, index) {
      this.timeNum--
      this.dataForm.timeList.splice(index, 1)
    },

    beforeSubmit() {
      this.$confirm('当前配置信息是否已确认无误?', '提示', {
        confirmButtonText: 'Yes',
        cancelButtonText: 'No',
        type: 'warning'
      }).then(() => {
        this.dataFormSubmit();
      })
    },

    // 表单提交
    dataFormSubmit() {
      this.$refs['dataForm'].validate((valid) => {
        if (valid) {

          this.dataForm.contentListBylang.forEach(item => {
            if (item.lang === 'ar') {
              this.dataForm.pushContentList.ar = this.dataForm.pushContentList.ar.concat(item.contentList)
              this.dataForm.pushContentList.ar = this.dataForm.pushContentList.ar.map(item => {
                return item.content;
              })
              this.dataForm.pushContentList.ar = this.dataForm.pushContentList.ar.filter(s => {
                return s && s.trim();
              });
              if (this.dataForm.pushContentList.ar.length !== 0) {
                this.dataForm.langList.push('ar')
              }
            } else if (item.lang === 'en') {
              this.dataForm.pushContentList.en = this.dataForm.pushContentList.en.concat(item.contentList)

              this.dataForm.pushContentList.en = this.dataForm.pushContentList.en.map(item => {
                return item.content;
              })
              this.dataForm.pushContentList.en = this.dataForm.pushContentList.en.filter(s => {
                return s && s.trim();
              });
              if (this.dataForm.pushContentList.en.length !== 0) {
                this.dataForm.langList.push('en')
              }
            }
          });
          this.dataForm.langList = Array.from(new Set(this.dataForm.langList))

          this.dataForm.sendTimeList = this.dataForm.timeList.map(item => {
            return item.time;
          })

          // delete this.dataForm.contentList
          // delete this.dataForm.contentListBylang
          // delete this.dataForm.language
          // delete this.dataForm.id

          // 删除Ip后删除对应的角色
          let tempVirList = []
          this.previousIpOptValue.forEach(item => {
            tempVirList = tempVirList.concat(item.virUserResDtoList)
          })
          tempVirList = tempVirList.map(item => {
            return item.id;
          })

          for (let i = 0; i < this.dataForm.virUserList.length; i++) {
            if (tempVirList.indexOf(this.dataForm.virUserList[i]) === -1) {
              this.dataForm.virUserList.splice(i, 1);
              i--;
            }
          }
          console.log('dataForm', this.dataForm)

          this.$http({
            url: this.$http.adornUrl(`/admin/pushTaskNew/add`),
            method: 'post',
            data: this.$http.adornData({
              ipList: JSON.stringify(this.dataForm.ipList),
              langList: JSON.stringify(this.dataForm.langList),
              pushContentList: JSON.stringify(this.dataForm.pushContentList),
              sendCcList: this.dataForm.sendCcList.includes('all') ? this.dataForm.sendCcList.toString() : JSON.stringify(this.dataForm.sendCcList),
              sendTimeList: JSON.stringify(this.dataForm.sendTimeList),
              virUserList: this.dataForm.virUserList.includes('all') ? this.dataForm.virUserList.toString() : JSON.stringify(this.dataForm.virUserList),
            })
          }).then(({ data }) => {
            if (data && data.code === 0) {
              this.$message({
                message: '操作成功',
                type: 'success',
                duration: 1500,
                onClose: () => {
                  this.visible = false
                  this.$emit('refreshDataList')
                }
              })
            } else {
              this.$message.error(data.msg)
            }
          })
        }
      })
    }
  },
}
</script>
<style scoped lang="scss">
::v-deep .el-dialog__body {
  padding: 0 20px;
}

h3 {
  padding: 2vh 0;
  border-top: 1px solid rgba(184, 184, 184, 0.6)
}

.input-width {
  width: 30vw;
  margin-bottom: 2vh;
}
</style>
